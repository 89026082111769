<template>
  <strategy-form></strategy-form>
</template>

<script>
import StrategyForm from "/src/components/steps/StrategyForm.vue";

export default {
  name: "Strategy",
  components: {
    StrategyForm,
  },
};
</script>

StrategyForm
<style></style>
